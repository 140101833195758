<template>
  <base-material-dialog
    :value="value"
    icon="far fa-fingerprint"
    title="Identifiers bearbeiten"
    color="primary"
    :actions="[ 'close' ]"
    @close="close"
    @esc="close"
  >
    <identifiers-table
      :collection="collection"
      :identifiers="identifiers"
      :id="id"
      :noauto="noauto"
      @add="(v) => $emit('add', v)"
      @del="(v) => $emit('del', v)"
    />
  </base-material-dialog>
</template>

<script>
import IdentifiersTable from '../components/identifiers'

export default {
  name: 'identifiers',

  components: {
    IdentifiersTable
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    noauto: {
      type: Boolean,
      required: false,
      default: false
    },
    identifiers: {
      type: Array,
      required: true
    },
    collection: {
      type: String,
      required: true
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>

</style>
