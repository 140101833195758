<template>
  <v-card
    flat
    class="mt-8"
  >
    <v-btn
      absolute
      top
      right
      fab
      small
      @click="open()"
    >
      <v-icon>
        fa-plus
      </v-icon>
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="identifiers"
      :items-per-page="15"
      :mobile-breakpoint="0"
    >
      <template #item.delete="{item}">
        <v-btn
          text
          small
          fab
          @click="del(item)"
        >
          <v-icon>
            far fa-trash-alt
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <base-material-dialog
      :value="newdialog.open"
      icon="far fa-fingerprint"
      title="Identifier hinzufügen"
      color="primary"
      :actions="[ 'save', 'cancel' ]"
      @save="add"
      @close="newdialog.open = false"
      @esc="newdialog.open = false"
    >
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="newdialog.name"
            label="Name"
          />
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            v-model="newdialog.value"
            label="Wert"
          />
        </v-col>
      </v-row>
    </base-material-dialog>
  </v-card>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

export default {
  name: 'identifiers',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    noauto: {
      type: Boolean,
      required: false,
      default: false
    },
    identifiers: {
      type: Array,
      required: true
    },
    collection: {
      type: String,
      required: true
    }
  },

  data: () => ({
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Wert',
        value: 'value',
        sortable: false
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    newdialog: {
      open: false,
      name: '',
      value: ''
    }
  }),

  methods: {
    open () {
      this.newdialog.name = ''
      this.newdialog.value = ''
      this.newdialog.open = true
    },
    add () {
      const variables = {
        id: this.id,
        identifier: {
          name: this.newdialog.name,
          value: this.newdialog.value
        }
      }

      console.log(this.id)

      this.newdialog.open = false

      if (this.noauto) return this.$emit('add', variables)

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $identifier: IIdentifier!) {
            ${this.collection === 'StbM2021' ? 'StbM2021UpdateIdentifierAdd' : `${this.collection}IdentifierAdd`}(id: $id, identifier: $identifier) {
              _id
            }
          }
        `,
        variables
      })
    },
    del (item) {
      const variables = {
        id: this.id,
        identifier: {
          name: item.name,
          value: item.value
        }
      }

      if (this.noauto) return this.$emit('del', variables)

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $identifier: IIdentifier!) {
            ${this.collection === 'StbM2021' ? 'StbM2021UpdateIdentifierDelete' : `${this.collection}IdentifierDelete`}(id: $id, identifier: $identifier) {
              _id
            }
          }
        `,
        variables
      })
    }
  }
}
</script>

<style scoped>

</style>
