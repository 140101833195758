<template>
  <v-container
    fluid
    tag="section"
  >
    <v-card flat>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="create()"
      >
        <v-icon>
          fa-plus
        </v-icon>
      </v-btn>

      <v-col>
        <v-text-field
          v-model="filter"
          label="Filter"
        />
      </v-col>

      <v-data-table
        :headers="headers"
        :items="VenueFind"
        mobile-breakpoint="0"
        sort-by="address.postalCode"
        :search="filter"
        :items-per-page="15"
      >
        <template #item.name="{item}">
          <base-edit-dialog
            :value="item.name"
            :id="item._id"
            label="Name"
            @input="({ value, id }) => savename(id, value)"
          />
        </template>
        <template #item.address.streetAddress="{item}">
          <base-edit-dialog
            :value="(item.address || {}).streetAddress"
            :id="item._id"
            label="Straße"
            @input="({ value, id }) => saveaddress(id, 'streetAddress', value)"
          />
        </template>
        <template #item.address.streetNumber="{item}">
          <base-edit-dialog
            :value="(item.address || {}).streetNumber"
            :id="item._id"
            label="Hausnummer"
            @input="({ value, id }) => saveaddress(id, 'streetNumber', value)"
          />
        </template>
        <template #item.address.postalCode="{item}">
          <base-edit-dialog
            :value="(item.address || {}).postalCode"
            :id="item._id"
            label="PLZ"
            @input="({ value, id }) => saveaddress(id, 'postalCode', value)"
          />
        </template>
        <template #item.address.addressLocality="{item}">
          <base-edit-dialog
            :value="(item.address || {}).addressLocality"
            :id="item._id"
            label="Ort"
            @input="({ value, id }) => saveaddress(id, 'addressLocality', value)"
          />
        </template>
        <template #item.address.addressCountry="{item}">
          <base-edit-dialog-select
            :value="(item.address || {}).addressCountry"
            :id="item._id"
            :items="[
              { value: 'de', text: 'Deutschland' },
              { value: 'at', text: 'Österreich' },
              { value: 'ch', text: 'Schweiz' }
            ]"
            label="Land"
            @input="({ value, id }) => saveaddress(id, 'addressCountry', value)"
          />
        </template>
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          <v-btn
            text
            small
            fab
            @click="openidentifiers(item)"
          >
            {{ (item.identifiers || []).length }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <identifiers
      v-model="identifiersdialog.open"
      :id="identifiersdialog.id"
      :identifiers="identifiersdialog.identifiers"
      collection="Venue"
    />
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { apolloDefault, useGraphQL } from '@/plugins/graphql'
import Identifiers from '../../core/dialogs/identifiers'

const query = `
  _id
  name
  address { streetAddress streetNumber postalCode addressLocality addressCountry }
  identifiers { name value }
`

export default {
  name: 'venues',

  components: {
    Identifiers
  },

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  data: () => ({
    VenueFind: [],
    headers: [
      {
        text: 'Name',
        value: 'name',
        sortable: true
      },
      {
        text: 'Straße',
        value: 'address.streetAddress',
        sortable: true
      },
      {
        text: 'Hausnummer',
        value: 'address.streetNumber',
        sortable: true
      },
      {
        text: 'PLZ',
        value: 'address.postalCode',
        sortable: true
      },
      {
        text: 'Ort',
        value: 'address.addressLocality',
        sortable: true
      },
      {
        text: 'Land',
        value: 'address.addressCountry',
        sortable: true
      },
      {
        text: 'IDs',
        value: 'identifiers',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Löschen',
        value: 'delete',
        sortable: false,
        align: 'center'
      }
    ],
    filter: '',
    identifiersdialog: {
      open: false,
      identifiers: [],
      id: ''
    }
  }),

  methods: {
    create () {
      this.mutate({
        mutation: gql`
          mutation { VenueCreate { ${query} }}
          `
      })
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { VenueDelete(id: $id) }
          `,
        variables: {
          id
        }
      })
    },
    savename (id, name) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $name: String!) {
            VenueUpdateName(id: $id, name: $name) {
              ${query}
            }
          }`,
        variables: {
          id,
          name
        }
      })
    },
    saveaddress (id, field, value) {
      const item = this.VenueFind.find(v => v._id === id)
      const neu = item.address || {}
      neu[field] = value
      delete neu.__typename

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $address: IPostalAddress!) {
            VenueUpdateAddress(id: $id, address: $address) {
              ${query}
            }
          }`,
        variables: {
          id: item._id,
          address: neu
        }
      })
    },
    openidentifiers (item) {
      this.identifiersdialog.id = item._id || ''
      this.identifiersdialog.identifiers = item.identifiers || []
      this.identifiersdialog.open = true
    }
  },

  watch: {
    VenueFind: {
      deep: true,
      handler (val) {
        if (!this.identifiersdialog.open) return
        this.identifiersdialog.identifiers = val.find(c => c._id === this.identifiersdialog.id)?.identifiers || []
      }
    }
  },

  apollo: apolloDefault('Venue', query)
}
</script>

<style scoped>

</style>
